import React, { useState } from 'react';
import styled from 'styled-components';
import Image from '../components/Image';
import Text from '../components/Text';
import Layout from '../global/Layout';

const Button = styled.button`
  background-color: silver;
  padding: 10px;
  margin: 10px;
`;
const Page = styled.div`
  width: 100%;
  height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Heading = styled.h1`
  font-size: 24px;
  color: #555;
  margin-top: 60px;
`;

const IndexPage = () => {
  const cookies = typeof window !== 'undefined' ? document.cookie.split('; ') : [];
  const [inPreview, setInPreview] = useState(cookies.indexOf('nf_ab=master') !== -1);

  const handleOptIn = () => {
    if (typeof window !== 'undefined') {
      window.document.cookie = 'nf_ab=master; expires=Thu, 01 Jan 2021 00:00:00 GMT';
      setInPreview(true);
      window.location.reload(true);
    }
  };

  const handleOptOut = () => {
    if (typeof window !== 'undefined') {
      window.document.cookie = 'nf_ab=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      setInPreview(false);
      window.location.reload(true);
    }
  };

  return (
    <Layout>
      <Page>
        <Image />
        <Heading>Opt-in Vorschau (Master)</Heading>
        <Text>
          {!inPreview && <Button onClick={handleOptIn}>ich möchte die Preview sehen</Button>}
          {inPreview && <Button onClick={handleOptOut}>ich möchte die Preview nicht mehr sehen</Button>}
        </Text>
      </Page>
    </Layout>
  );
};

export default IndexPage;
